import styled from 'styled-components'
import device from '../../styles/device'
import 'typeface-mulish'
import 'typeface-muli'

const FooterContainer = styled.footer`
  background-color: ${props => props.theme.footer};
  padding: .8rem;
  padding-bottom: 1rem;
  display: flex;
  min-height: 8rem;
  flex-direction: column;
  text-align: center;

  @media ${device.md} {
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
  }

  @media ${device.xl} {
    flex-direction: row;
    text-align: left;
  }
`

const FooterItemTitle = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
`

const FooterItem = styled.div`
  flex: 1;
  color: white;
  padding: 1rem;

  @media ${device.md} {
    flex: 50%;
  }

  @media ${device.xl} {
    flex: 1;
  }
`

const FooterListItem = styled.li`
  padding: 0.5rem 0;
  font-size: .8rem;
`

export {
  FooterContainer,
  FooterItem,
  FooterItemTitle,
  FooterListItem
}