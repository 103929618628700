import styled, { css } from 'styled-components'
import Link from 'next/link'
import device from './device'
import { space, layout } from 'styled-system'
import {  
  Input,
  Select,
  Label,
  InputField,
  Radio
} from './input.elements'

const center = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Button = styled.button`
  padding: .8rem 1rem;
  background-color: ${props => props.secondary ? 
    props.theme.secondary.main : props.theme.primary.main};
  color: white;
  border: none;
  border-radius: 10px;
  outline: none;

  font-family: 'Mulish';
  font-size: 0.7rem;
  font-weight: bold;
  letter-spacing: .1rem;
  text-align: center;
  text-transform: uppercase;

  ${space}

  &:hover {
    background-color: ${props => props.secondary ? 
      props.theme.secondary.light : props.theme.primary.light};
    color: black;
  }

  &:active {
    background-color: ${props => props.secondary ? 
      props.theme.secondary.dark : props.theme.primary.dark};
    color: white;
    border: none;
  }

  &:disabled {
    background-color: #606060;
    color: white;
    border: none;
  }
`

const ButtonLink = ({ href, children, ...rest }) => (
  <Link href={href} passHref>
    <Button {...rest}>{children}</Button>
  </Link>
)

const Panel = styled.div`
  ${props => props.fullHeight && 'height: 100%;'}
  ${props => props.centered && center}
  padding: 1rem;
  text-align: center;
  background-color: ${props => {
    switch(props.variant) {
      case "white": return "white"
      case "grey": return "#F2F2F2"
      case "dark-grey": return "#E0E0E0"
      case "dark-transparent": return "rgba(0, 0, 0, 50%)"
      default: return "#F2F2F2"
    }
  }};
  color: ${props => props.variant === "dark-transparent" ? "white" : "black"};
  border-radius: 15px;
  ${space}
  ${layout}
`

const Card = styled(Panel)`
  box-shadow: 0 8px 10px rgba(0, 0, 0, 35%)
`

const media = (size, styles) => (`
  @media ${device[size]} {
    ${styles}
  }
`)

const Grid = styled.div`  
  display: flex;
  flex-direction: ${props => props.direction ? props.direction : 'column'};
  justify-content: ${props => props.justify ? props.justify : 'center'};
  align-items: ${props => props.align ? props.align : 'center'};
  flex-wrap: ${props => props.flexWrap ? props.flexWrap : 'no-wrap'};
  ${props => props.breakpoint && media(props.breakpoint, 'flex-direction: row;')}
  ${props => props.stretchOnBreakpoint && media(props.breakpoint, 'align-items: stretch;')}
  ${space}
  ${layout}
`

const Item = styled.div`
  flex: ${props => props.size ? props.size : '1'};
  ${props => props.collapse && media(props.collapse, 'display: none;')}
  ${space}
  ${layout}
`

const Badge = styled.span`
  display: inline-block;
  min-width: .2rem;
  padding: .1rem .5rem;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  background: ${props => props.color ? props.color : props.theme.secondary.main};
  color: #fefefe;
`

export {
  Button,
  ButtonLink,
  Panel,
  Card,
  Input,
  Select,
  Label,
  InputField,
  Radio,
  Grid,
  Item,
  Badge
}