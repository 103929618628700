
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { createUploadLink } from 'apollo-upload-client'
import store from '../../redux'

const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_APOLLO_SERVER_URI
})

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_APOLLO_SERVER_URI
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      let errors = []
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
      errors.push(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      errors.join(' || ')
      throw new Error(errors)
    })

  if (networkError) console.log(`[Network error]: ${networkError}`)
  throw new Error(`[Network error]: ${networkError}`)
})

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error
  }
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const auth = store.getState().root.auth

  if (auth && auth.token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${auth.token}`,
      }
    })
  }

  return forward(operation)
})

const client = new ApolloClient({
  link: process.env.NODE_ENV === "production" ? from([authMiddleware, retryLink, uploadLink]) 
    : from([authMiddleware, errorLink, retryLink, uploadLink]),
  // link: from([authMiddleware, errorLink, retryLink, httpLink]),
  cache: new InMemoryCache()
})

export default client