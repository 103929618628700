const theme = {
  primary: {
    main: "#01827B",
    light: "#4EBAAA",
    dark: "#005B4F"
  },
  secondary: {
    main: "#D32F2F",
    light: "#FF6659",
    dark: "#9A0007"
  },
  footer: "#1E2A33"
}

export default theme