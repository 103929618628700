import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client/react';
import apolloClient from '../gql/apollo';

import store, { persistor } from '../redux';

import theme from '../styles/theme';
import { Layout } from '../components';


function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Head>
              <link rel="icon" href="/favicon.png" />
              <meta name="description" content="Conservador de Bienes Raíces de
              Puerto Varas. Regístrate para recibir todas las ventajas de nuestros
              servicios y poder solicitar copias y/o certificados."/>
            </Head>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

export default MyApp;
