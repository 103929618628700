import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../index'

type UserStatus = {
  scanViewed: number;
}

const initialState: UserStatus = {
  scanViewed: 0
}

const userStatusSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addScanViewed: (state) => ({
      scanViewed: state.scanViewed + 1
    }),
    resetScanViewed: (state) => ({
      scanViewed: 0
    }),
  },
})

export default userStatusSlice.reducer
export const { addScanViewed, resetScanViewed } = userStatusSlice.actions

export const getScanViewed = (state: RootState) => {
  return state.root.userStatus?.scanViewed ?? 0;
};
