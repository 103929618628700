import { createSlice } from "@reduxjs/toolkit"

const blueprintsDocsCartSlice = createSlice({
  name: 'planeDocsCart',
  initialState: [],
  reducers: {
    addToBlueprintsDocsCart: (state, action) => void(state.push(action.payload)),
    removeFromBlueprintsDocsCart: (state, action) => 
      state.filter(request => request.tempId != action.payload.tempId),
    resetBlueprintsDocsCart: _ => []
  }
})

export default blueprintsDocsCartSlice.reducer
export const { 
  addToBlueprintsDocsCart, 
  removeFromBlueprintsDocsCart,
  resetBlueprintsDocsCart
 } = blueprintsDocsCartSlice.actions