import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistCombineReducers, persistStore } from 'redux-persist'

import authReducer from './slices/authSlice'
import cartReducer from './slices/cartSlice'
import blueprintsDocsCartReducer from './slices/blueprintsDocsCartSlice'
import userStatusReducer from './slices/userStatus'

const persistConfig = {
  key: 'auth',
  storage
}

const rootReducer = persistCombineReducers(persistConfig, {
  auth: authReducer,
  cart: cartReducer,
  blueprintsDocsCart: blueprintsDocsCartReducer,
  userStatus: userStatusReducer,
});

const store = configureStore({
  reducer: {
    root: rootReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
})

const persistor = persistStore(store)

export default store
export {
  persistor
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch