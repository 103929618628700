import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../index'
import User from '../../model/User'

type UserToken = {
  user: User
  token: string
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {} as UserToken | object,
  reducers: {
    login: (_, action) => action.payload,
    logout: (_) => null,
  },
})

export function getIsLogged(state: RootState) {  
  return (state.root.auth && 'token' in state.root.auth) ? true : false
}

export default authSlice.reducer
export const { login, logout } = authSlice.actions
