import { useSelector } from 'react-redux'
import {
  FooterContainer,
  FooterItem,
  FooterItemTitle,
  FooterListItem
} from './footer.elements'
import Link from 'next/link'
import Image from 'next/image'

const Footer = () => {
  const isLogged = useSelector(state => {
    if (state.root.auth && 'token' in state.root.auth) {
      return true
    }
    return false
  })
  return (
    <FooterContainer>
      <FooterItem>
        <Image
          src="/logo.png"
          alt="CBRPV Logo"
          layout="responsive"
          width={300}
          height={94}
        />
      </FooterItem>
      <FooterItem>
        <FooterItemTitle>Información</FooterItemTitle>
        <ul>
          <FooterListItem>
            <Link href="/info/contact">Datos de contacto</Link>
          </FooterListItem>
          <FooterListItem>
            <Link href="/info/documentTypes">Tipos de documentos</Link>
          </FooterListItem>
          <FooterListItem>
            <Link href="/info/faq">Preguntas frecuentes</Link>
          </FooterListItem>
        </ul>        
      </FooterItem>
      <FooterItem>
        <FooterItemTitle>Contacto</FooterItemTitle>
        <ul>
          <FooterListItem>
            <strong>Teléfono</strong>: 065-2332680
          </FooterListItem>
          <FooterListItem>
            <strong>Emails</strong>
            <ul>
              <li>
                <strong>Consultas</strong>: consultas@conservadorpvaras.cl
              </li>
              <li>
                <strong>Soporte</strong>: informatica@conservadorpvaras.cl
              </li>
            </ul>
          </FooterListItem>
          <FooterListItem>
            <strong>Dirección</strong>: Ramón Ricardo Rosas #463
          </FooterListItem>
          <FooterListItem>
            <strong>Horario de atención</strong>: 8:30 a 13:30
          </FooterListItem>
        </ul>        
      </FooterItem>
      <FooterItem>
        <FooterItemTitle>Usuario</FooterItemTitle>
        <ul>
          {isLogged ? (
            <>
              <FooterListItem>
                <Link href="/profile">Perfil</Link>
              </FooterListItem>
            </>
          ) : (
            <>
              <FooterListItem>
                <Link href="/auth/login">Ingresa</Link>
              </FooterListItem>
              <FooterListItem>
                <Link href="/auth/register">Crea una cuenta</Link>
              </FooterListItem>
            </>
          )}
          <FooterListItem>
            <Link href="/auth/changePassword">Cambia tu contraseña</Link>
          </FooterListItem>
        </ul>
      </FooterItem>
    </FooterContainer>
  )
}
 
export default Footer